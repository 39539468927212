import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import logoImage from '../assets/logo/blue.svg';
import messages from '../assets/messages_he.json';
import MenuImg from '../assets/icons/blueMenu.svg';
import MainLayout from '../common/layouts/MainLayout';
import MainList from '../common/layouts/main_list/MainList';
import MainListItem from '../common/layouts/main_list/MainListItem';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import { useGames } from './useGames';
import { games } from 'src/metadata';
import CircleButton from 'src/common/buttons/CircleButton';
import TextButton from 'src/common/buttons/TextButton';
import backSvg from 'src/assets/icons/back.svg';
import '../common/layouts/main_list/MainList.css';
import './Games.css';

function Games(): JSX.Element {
    const { state } = useContext(AppContext);
    const { getMQid } = useMediaQueryWrap();
    const { selectGameGroup, resetGameSelection, selectGame } = useGames();

    const render_group_games_list = () => {
        if (state.course_key === '') return;
        return games[state.course_key].map((item, index) => {
            const locked = !(state.course_key in state.courses);

            return (
                <MainListItem
                    key={index}
                    main_img={item.img}
                    title={item.title}
                    locked={locked}
                    onClick={() => selectGameGroup(index)}
                    small_text={item.desc}
                />
            );
        });
    };

    const render_games_list = () => {
        if (state.course_key === '') return;

        return games[state.course_key][state.curr_game_group_idx].games.map((item, index) => {
            return (
                <MainListItem
                    key={index}
                    main_img={item.img}
                    title={item.title}
                    locked={false}
                    onClick={() => selectGame(index)}
                />
            );
        });
    };

    const render_float_back_button = () => {
        if (getMQid() !== 'mobile') return undefined;
        if (state.curr_game_group_idx === -1) return undefined;
        return (
            <div className="floating_back_btn">
                <CircleButton
                    svg={backSvg}
                    zoom={0.5}
                    color={'blue'}
                    onClick={resetGameSelection}
                    ariaLabel={messages['games.back']}
                />
            </div>
        );
    };

    const render_back_button = () => {
        if (state.curr_game_group_idx === -1) return undefined;
        return (
            <div className="mlButtonsWrapper">
                <CircleButton
                    svg={backSvg}
                    color={'blue'}
                    onClick={resetGameSelection}
                    ariaLabel={messages['games.back']}
                />
                <TextButton
                    text={messages['games.back']}
                    onClick={resetGameSelection}
                    textColor={'#0761A6'}
                    fontSize={'1.25rem'}
                    fontWeight={800}
                    underline
                />
            </div>
        );
    };

    const get_title = () => {
        if (state.course_key === '') return;
        if (state.curr_game_group_idx === -1) return `${messages['games.header']}`;
        return games[state.course_key][state.curr_game_group_idx].title;
    };

    const get_game_description = () => {
        if (state.course_key === '') return '';
        if (state.curr_game_group_idx === -1) return '';
        return games[state.course_key][state.curr_game_group_idx].desc;
    };

    return (
        <MainLayout can_url="/games" id={`class_${getMQid()}`} logo={logoImage} appMenuLogo={MenuImg} in_a_course>
            <MainList
                text={
                    <>
                        <h1 className={'mlMainHeader'}>{get_title()}</h1>
                        <p className="mlSubHeader">{get_game_description()}</p>
                    </>
                }
                mainList={state.curr_game_group_idx === -1 ? render_group_games_list() : render_games_list()}
                bottomButtons={render_back_button()}
            />
            {render_float_back_button()}
        </MainLayout>
    );
}

export default Games;
