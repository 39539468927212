import React from 'react';
import RudiGarden from '../../assets/images/rudi-garden.webp';
import Video from 'src/features/video/Video';
import videoImage from 'src/assets/images/landing1.webp';

import './sec-meet.css';
import { log_event, scroll_to_element } from 'src/common/common';

const first_vid = 'https://youtu.be/1c5GPGZdWkg';

interface secMeetProps {
    zoom_gift: boolean;
    primBtnText: string;
}

function SecMeetAlef({ zoom_gift, primBtnText }: secMeetProps) {
    const [play, setPlay] = React.useState(false);

    return (
        <section className="sec-meet py-4">
            <div className="container flex items-center justify-between">
                <div className="m-100 w-50 desk-pr-1 text-center">
                    <h1 className="title-42">חובה ל-א׳!</h1>
                    <h2 className="text-primary text-xl font-bold">הקורסים הדיגיטליים של ״דמיון בקופסא״:</h2>
                    <h2 className="text-primary text-xl">הופכים את הילדים לאלופי המספרים, האותיות והצלילים</h2>

                    <br></br>

                    <Video
                        url={first_vid}
                        vid_over_cb={() => null}
                        img={videoImage}
                        play={play}
                        setPlay={setPlay}
                        controls={true}
                        log_click={'landing_1st_vid'}
                    />
                </div>
                <div className="m-100 w-50 desk-pr-1 text-center">
                    <div className="mx-auto mt-1">
                        <p className="font-bold">מה קורה לילדים כשהם עולים לכיתה א׳?</p>
                        <br></br>

                        <p>שלום ילדים והורים!</p>
                        <p>
                            שמי רוי גקט, ואתם אולי מכירים אותי מהטלוויזיה (רוי הגנן). מניסיוני כמחנך כיתות א׳ וב׳, אני
                            יודע שהאופן שבו מתחילים הילדים שלנו את המסע בבית הספר ישפיע ויעצב את אישיותם. ילדים שמתקשים
                            עם החומרים בכיתה א׳, יתקשו גם עם החומרים של הכיתות הבאות – כי הכל מבוסס על... ובכן, על
                            הבסיס.
                        </p>
                        <br></br>

                        <p>
                            ילד או ילדה שאינם בטוחים דיים בעצמם עלולים לחוות קשיים מכל מיני סוגים, כמו למשל תחושת תסכול,
                            להרגיש שהם ׳פחות מוצלחים׳ וכו׳. ללא בסיס טוב, הקשיים יכולים להגיע גם לכיתות הגבוהות, לבחינות
                            הבגרות ומעבר להן.
                        </p>

                        <br></br>
                        <p className="font-bold">
                            אבל ילדים שמגיעים לכיתה א׳ כשהם כבר יודעים את החומר, מקבלים מייד חיזוקים חיוביים מהמורים
                            ומהחברים שלהם!
                        </p>
                        <p>
                            הם מרגישים בטוחים בעצמם, והם מוכנים יותר לחומרים הבאים. זה ׳כדור שלג חיובי׳, שבו חוויה טובה
                            מובילה לעוד חוויה טובה, ותעודה טובה מובילה לעוד תעודה טובה. כך עד לבגרות, לצבא ולאוניברסיטה.
                        </p>
                        <br></br>

                        <p className="font-bold">* ניתן לגלוש באתר הקורס דרך המחשב, הטאבלט והטלפון הנייד שלכם.</p>
                        <div className="mt-1">
                            <img
                                className="rudi-garden land_img"
                                src={RudiGarden}
                                alt="rudi-garden"
                                width={184}
                                height={160}
                            />
                        </div>
                        {zoom_gift && (
                            <p>
                                🎁 בונוס למצטרפים חדשים: מפגש זום חוויתי בו יפתרו הילדים חידות ויתרגלו את המיומנויות
                                החשובות לקראת קריאה וכתיבה🎁
                            </p>
                        )}
                        <button
                            className="bt-primary green"
                            aria-label={primBtnText}
                            onClick={() => {
                                scroll_to_element('SecSell');
                                log_event('landing_payment_modal1');
                            }}
                        >
                            {primBtnText}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SecMeetAlef;
