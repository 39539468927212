import React from 'react';
import AppRights from '../AppRights';
import PaymentModal from '../payment/PaymentModal';
import LoginModal from '../login/LoginModal';
import AddresModal from '../address/AddressModal';
import { Helmet } from 'react-helmet';
import MainHeader from './MainHeader';
import './MainLayout.css';

export type layoutId =
    | 'home_desktop'
    | 'home_mobile'
    | 'home_tablet'
    | 'cr_desktop'
    | 'cr_tablet'
    | 'cr_mobile'
    | 'class_desktop'
    | 'class_tablet'
    | 'class_mobile'
    | 'promo_desktop'
    | 'promo_tablet'
    | 'promo_mobile'
    | 'folder_desktop'
    | 'folder_tablet'
    | 'folder_mobile'
    | 'moretasks_desktop'
    | 'moretasks_tablet'
    | 'moretasks_mobile'
    | 'tips_desktop'
    | 'tips_tablet'
    | 'tips_mobile'
    | 'notfound'
    | 'text';

export interface IMainLayout {
    id: layoutId;
    logo: string;
    appMenuLogo: string;
    can_url?: string;
    in_a_course?: boolean;
    children: React.ReactNode;
}

const base_url = 'https://imagineinabox.com';

function MainLayout(props: IMainLayout): JSX.Element {
    return (
        <>
            <Helmet>{props.can_url && <link rel="canonical" href={base_url + props.can_url} />}</Helmet>
            <div id={props.id} className="mainLayoutBackground">
                <div id={props.id} className="mainLayoutContent">
                    <MainHeader {...props} />
                    {props.children}
                    <AppRights id={props.id} />
                </div>
            </div>
            <AddresModal />
            <PaymentModal />
            <LoginModal />
        </>
    );
}

export default MainLayout;
