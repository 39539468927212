// the nature of this game - it is faster to hold the images locally rather than on srver
// need sophisticated mechanisem to load from server... i'll skip for now

// lvl 1
import clock from 'src/assets/images/memory_card_game/lvl1/clock.webp';
import horse from 'src/assets/images/memory_card_game/lvl1/horse.webp';
import fish from 'src/assets/images/memory_card_game/lvl1/fish.webp';
import lemon from 'src/assets/images/memory_card_game/lvl1/lemon.webp';
import pineapple from 'src/assets/images/memory_card_game/lvl1/pineapple.webp';
import baloon from 'src/assets/images/memory_card_game/lvl1/baloon.webp';
import a from 'src/assets/images/memory_card_game/lvl1/a.webp';
import b from 'src/assets/images/memory_card_game/lvl1/b.webp';
import shin from 'src/assets/images/memory_card_game/lvl1/shin.webp';
import d from 'src/assets/images/memory_card_game/lvl1/d.webp';
import s from 'src/assets/images/memory_card_game/lvl1/s.webp';
import l from 'src/assets/images/memory_card_game/lvl1/l.webp';
import lvl1Ins from 'src/assets/images/memory_card_game/lvl1/lvl1.mp3';

// lvl 2
import img1 from 'src/assets/images/memory_card_game/lvl2/1-min.webp';
import img2 from 'src/assets/images/memory_card_game/lvl2/2-min.webp';
import img2_a from 'src/assets/images/memory_card_game/lvl2/2a-min.webp';
import img3 from 'src/assets/images/memory_card_game/lvl2/3-min.webp';
import img3_a from 'src/assets/images/memory_card_game/lvl2/3a-min.webp';
import img4 from 'src/assets/images/memory_card_game/lvl2/4-min.webp';
import img4_a from 'src/assets/images/memory_card_game/lvl2/4a-min.webp';
import img5 from 'src/assets/images/memory_card_game/lvl2/5-min.webp';
import img5_a from 'src/assets/images/memory_card_game/lvl2/5a-min.webp';
import img6 from 'src/assets/images/memory_card_game/lvl2/6-min.webp';
import img6_a from 'src/assets/images/memory_card_game/lvl2/6a-min.webp';
import lvl2Ins from 'src/assets/images/memory_card_game/lvl2/lvl2.mp3';

// lvl 8
import lvl8_1 from 'src/assets/images/memory_card_game/lvl8/1a-min.webp';
import lvl8_1a from 'src/assets/images/memory_card_game/lvl8/1-min.webp';
import lvl8_2 from 'src/assets/images/memory_card_game/lvl8/2-min.webp';
import lvl8_2a from 'src/assets/images/memory_card_game/lvl8/2a-min.webp';
import lvl8_3 from 'src/assets/images/memory_card_game/lvl8/3-min.webp';
import lvl8_3a from 'src/assets/images/memory_card_game/lvl8/3a-min.webp';
import lvl8_4 from 'src/assets/images/memory_card_game/lvl8/4-min.webp';
import lvl8_5 from 'src/assets/images/memory_card_game/lvl8/5-min.webp';
import lvl8_5a from 'src/assets/images/memory_card_game/lvl8/5a-min.webp';
import lvl8_6 from 'src/assets/images/memory_card_game/lvl8/6-min.webp';
import lvl8_6a from 'src/assets/images/memory_card_game/lvl8/6a-min.webp';
import lvl8Ins from 'src/assets/images/memory_card_game/lvl8/lvl8.mp3';

import lvl3_1 from 'src/assets/images/memory_card_game/lvl3/lvl3_1-min.webp';
import lvl3_2 from 'src/assets/images/memory_card_game/lvl3/lvl3_2-min.webp';
import lvl3_3 from 'src/assets/images/memory_card_game/lvl3/lvl3_3-min.webp';
import lvl3_4 from 'src/assets/images/memory_card_game/lvl3/lvl3_4-min.webp';
import lvl3_5 from 'src/assets/images/memory_card_game/lvl3/lvl3_5-min.webp';
import lvl3_6 from 'src/assets/images/memory_card_game/lvl3/lvl3_6-min.webp';

import lvl4_1 from 'src/assets/images/memory_card_game/lvl4/lvl4_1-min.webp';
import lvl4_2 from 'src/assets/images/memory_card_game/lvl4/lvl4_2-min.webp';
import lvl4_3 from 'src/assets/images/memory_card_game/lvl4/lvl4_3-min.webp';
import lvl4_4 from 'src/assets/images/memory_card_game/lvl4/lvl4_4-min.webp';
import lvl4_5 from 'src/assets/images/memory_card_game/lvl4/lvl4_5-min.webp';
import lvl4_6 from 'src/assets/images/memory_card_game/lvl4/lvl4_6-min.webp';

import lvl5_1 from 'src/assets/images/memory_card_game/lvl5/lvl5_1-min.webp';
import lvl5_2 from 'src/assets/images/memory_card_game/lvl5/lvl5_2-min.webp';
import lvl5_3 from 'src/assets/images/memory_card_game/lvl5/lvl5_3-min.webp';
import lvl5_4 from 'src/assets/images/memory_card_game/lvl5/lvl5_4-min.webp';
import lvl5_5 from 'src/assets/images/memory_card_game/lvl5/lvl5_5-min.webp';
import lvl5_6 from 'src/assets/images/memory_card_game/lvl5/lvl5_6-min.webp';

import lvl6_1 from 'src/assets/images/memory_card_game/lvl6/lvl6_1-min.webp';
import lvl6_2 from 'src/assets/images/memory_card_game/lvl6/lvl6_2-min.webp';
import lvl6_3 from 'src/assets/images/memory_card_game/lvl6/lvl6_3-min.webp';
import lvl6_4 from 'src/assets/images/memory_card_game/lvl6/lvl6_4-min.webp';
import lvl6_5 from 'src/assets/images/memory_card_game/lvl6/lvl6_5-min.webp';
import lvl6_6 from 'src/assets/images/memory_card_game/lvl6/lvl6_6-min.webp';

import lvl7_1 from 'src/assets/images/memory_card_game/lvl7/lvl7_1-min.webp';
import lvl7_2 from 'src/assets/images/memory_card_game/lvl7/lvl7_2-min.webp';
import lvl7_3 from 'src/assets/images/memory_card_game/lvl7/lvl7_3-min.webp';
import lvl7_4 from 'src/assets/images/memory_card_game/lvl7/lvl7_4-min.webp';
import lvl7_5 from 'src/assets/images/memory_card_game/lvl7/lvl7_5-min.webp';
import lvl7_6 from 'src/assets/images/memory_card_game/lvl7/lvl7_6-min.webp';

// Math1

import math_sound_plus from 'src/assets/sounds/math_mem_plus.mp3';
import math_sound_minus from 'src/assets/sounds/math_mem_minus.mp3';
import math_sound_amount from 'src/assets/sounds/math_mem_amount.mp3';

// lvl1
import mlvl1_a1 from 'src/assets/images/memory_card_game/math1_lvl1/A1.webp';
import mlvl1_b1 from 'src/assets/images/memory_card_game/math1_lvl1/B1.webp';
import mlvl1_c1 from 'src/assets/images/memory_card_game/math1_lvl1/C1.webp';
import mlvl1_d1 from 'src/assets/images/memory_card_game/math1_lvl1/D1.webp';
import mlvl1_e1 from 'src/assets/images/memory_card_game/math1_lvl1/E1.webp';
import mlvl1_f1 from 'src/assets/images/memory_card_game/math1_lvl1/F1.webp';

import mlvl1_a2 from 'src/assets/images/memory_card_game/math1_lvl1/A2.webp';
import mlvl1_b2 from 'src/assets/images/memory_card_game/math1_lvl1/B2.webp';
import mlvl1_c2 from 'src/assets/images/memory_card_game/math1_lvl1/C2.webp';
import mlvl1_d2 from 'src/assets/images/memory_card_game/math1_lvl1/D2.webp';
import mlvl1_e2 from 'src/assets/images/memory_card_game/math1_lvl1/E2.webp';
import mlvl1_f2 from 'src/assets/images/memory_card_game/math1_lvl1/F2.webp';

// lvl2
import mlvl2_a1 from 'src/assets/images/memory_card_game/math1_lvl2/5.webp';
import mlvl2_b1 from 'src/assets/images/memory_card_game/math1_lvl2/6.webp';
import mlvl2_c1 from 'src/assets/images/memory_card_game/math1_lvl2/7.webp';
import mlvl2_d1 from 'src/assets/images/memory_card_game/math1_lvl2/8.webp';
import mlvl2_e1 from 'src/assets/images/memory_card_game/math1_lvl2/9.webp';
import mlvl2_f1 from 'src/assets/images/memory_card_game/math1_lvl2/10.webp';

import mlvl2_a2 from 'src/assets/images/memory_card_game/math1_lvl2/5a.webp';
import mlvl2_b2 from 'src/assets/images/memory_card_game/math1_lvl2/6a.webp';
import mlvl2_c2 from 'src/assets/images/memory_card_game/math1_lvl2/7a.webp';
import mlvl2_d2 from 'src/assets/images/memory_card_game/math1_lvl2/8a.webp';
import mlvl2_e2 from 'src/assets/images/memory_card_game/math1_lvl2/9a.webp';
import mlvl2_f2 from 'src/assets/images/memory_card_game/math1_lvl2/10a.webp';

// lvl3
import mlvl3_a1 from 'src/assets/images/memory_card_game/math1_lvl3/1.webp';
import mlvl3_b1 from 'src/assets/images/memory_card_game/math1_lvl3/2.webp';
import mlvl3_c1 from 'src/assets/images/memory_card_game/math1_lvl3/3.webp';
import mlvl3_d1 from 'src/assets/images/memory_card_game/math1_lvl3/4.webp';
import mlvl3_e1 from 'src/assets/images/memory_card_game/math1_lvl3/5.webp';
import mlvl3_f1 from 'src/assets/images/memory_card_game/math1_lvl3/6.webp';

import mlvl3_a2 from 'src/assets/images/memory_card_game/math1_lvl3/1a.webp';
import mlvl3_b2 from 'src/assets/images/memory_card_game/math1_lvl3/2a.webp';
import mlvl3_c2 from 'src/assets/images/memory_card_game/math1_lvl3/3a.webp';
import mlvl3_d2 from 'src/assets/images/memory_card_game/math1_lvl3/4a.webp';
import mlvl3_e2 from 'src/assets/images/memory_card_game/math1_lvl3/5a.webp';
import mlvl3_f2 from 'src/assets/images/memory_card_game/math1_lvl3/6a.webp';

// lvl4
import mlvl4_a1 from 'src/assets/images/memory_card_game/math1_lvl4/11.webp';
import mlvl4_b1 from 'src/assets/images/memory_card_game/math1_lvl4/12.webp';
import mlvl4_c1 from 'src/assets/images/memory_card_game/math1_lvl4/13.webp';
import mlvl4_d1 from 'src/assets/images/memory_card_game/math1_lvl4/14.webp';
import mlvl4_e1 from 'src/assets/images/memory_card_game/math1_lvl4/15.webp';
import mlvl4_f1 from 'src/assets/images/memory_card_game/math1_lvl4/16.webp';

import mlvl4_a2 from 'src/assets/images/memory_card_game/math1_lvl4/11a.webp';
import mlvl4_b2 from 'src/assets/images/memory_card_game/math1_lvl4/12a.webp';
import mlvl4_c2 from 'src/assets/images/memory_card_game/math1_lvl4/13a.webp';
import mlvl4_d2 from 'src/assets/images/memory_card_game/math1_lvl4/14a.webp';
import mlvl4_e2 from 'src/assets/images/memory_card_game/math1_lvl4/15a.webp';
import mlvl4_f2 from 'src/assets/images/memory_card_game/math1_lvl4/16a.webp';

const cells = 12;
export interface IMemoryCardsConfig {
    pairs: number[];
    pairs_imgs: string[];
    instructions?: any;
}

interface IMemoryCardsDict {
    [key: number]: IMemoryCardsConfig;
}

interface IPair {
    c1: number;
    c2: number;
    img1: string;
    img2: string;
}

type PairsConfig = Omit<IMemoryCardsConfig, 'instructions'>;

export function useMemoryCardsConfig() {
    const pair2config = (pairs: IPair[]): PairsConfig => {
        const pairs_flat: number[] = [...Array(cells)];
        const pairs_imgs_flat: string[] = [...Array(cells)];

        pairs.forEach((pair) => {
            pairs_flat[pair.c1] = pair.c2;
            pairs_flat[pair.c2] = pair.c1;
            pairs_imgs_flat[pair.c1] = pair.img1;
            pairs_imgs_flat[pair.c2] = pair.img2;
        });
        return {
            pairs: pairs_flat,
            pairs_imgs: pairs_imgs_flat,
        };
    };

    const pairs1: IPair[] = [
        { c1: 0, c2: 5, img1: clock, img2: shin },
        { c1: 2, c2: 4, img1: horse, img2: s },
        { c1: 3, c2: 7, img1: fish, img2: d },
        { c1: 6, c2: 9, img1: lemon, img2: l },
        { c1: 8, c2: 11, img1: pineapple, img2: a },
        { c1: 1, c2: 10, img1: baloon, img2: b },
    ];

    const pairs2: IPair[] = [
        { c1: 0, c2: 7, img1: img1, img2: baloon },
        { c1: 1, c2: 9, img1: img2, img2: img2_a },
        { c1: 4, c2: 2, img1: img3, img2: img3_a },
        { c1: 6, c2: 3, img1: img4, img2: img4_a },
        { c1: 8, c2: 5, img1: img5, img2: img5_a },
        { c1: 11, c2: 10, img1: img6, img2: img6_a },
    ];

    const pairs8: IPair[] = [
        { c1: 0, c2: 11, img1: lvl8_1, img2: lvl8_1a },
        { c1: 1, c2: 8, img1: lvl8_2, img2: lvl8_2a },
        { c1: 2, c2: 9, img1: lvl8_3, img2: lvl8_3a },
        { c1: 7, c2: 10, img1: lvl8_4, img2: fish },
        { c1: 4, c2: 5, img1: lvl8_5, img2: lvl8_5a },
        { c1: 3, c2: 6, img1: lvl8_6, img2: lvl8_6a },
    ];

    const pairs3: IPair[] = [
        { c1: 0, c2: 9, img1: lvl3_1, img2: lvl3_1 },
        { c1: 1, c2: 7, img1: lvl3_2, img2: lvl3_2 },
        { c1: 4, c2: 6, img1: lvl3_3, img2: lvl3_3 },
        { c1: 5, c2: 11, img1: lvl3_4, img2: lvl3_4 },
        { c1: 8, c2: 3, img1: lvl3_5, img2: lvl3_5 },
        { c1: 2, c2: 10, img1: lvl3_6, img2: lvl3_6 },
    ];

    const pairs4: IPair[] = [
        { c1: 0, c2: 6, img1: lvl4_1, img2: lvl4_1 },
        { c1: 1, c2: 11, img1: lvl4_2, img2: lvl4_2 },
        { c1: 4, c2: 10, img1: lvl4_3, img2: lvl4_3 },
        { c1: 5, c2: 9, img1: lvl4_4, img2: lvl4_4 },
        { c1: 8, c2: 2, img1: lvl4_5, img2: lvl4_5 },
        { c1: 3, c2: 7, img1: lvl4_6, img2: lvl4_6 },
    ];

    const pairs5: IPair[] = [
        { c1: 0, c2: 8, img1: lvl5_1, img2: lvl5_1 },
        { c1: 1, c2: 3, img1: lvl5_2, img2: lvl5_2 },
        { c1: 4, c2: 10, img1: lvl5_3, img2: lvl5_3 },
        { c1: 6, c2: 11, img1: lvl5_4, img2: lvl5_4 },
        { c1: 7, c2: 9, img1: lvl5_5, img2: lvl5_5 },
        { c1: 2, c2: 5, img1: lvl5_6, img2: lvl5_6 },
    ];

    const pairs6: IPair[] = [
        { c1: 0, c2: 3, img1: lvl6_1, img2: lvl6_1 },
        { c1: 1, c2: 8, img1: lvl6_2, img2: lvl6_2 },
        { c1: 2, c2: 10, img1: lvl6_3, img2: lvl6_3 },
        { c1: 4, c2: 11, img1: lvl6_4, img2: lvl6_4 },
        { c1: 6, c2: 9, img1: lvl6_5, img2: lvl6_5 },
        { c1: 7, c2: 5, img1: lvl6_6, img2: lvl6_6 },
    ];

    const pairs7: IPair[] = [
        { c1: 0, c2: 2, img1: lvl7_1, img2: lvl7_1 },
        { c1: 1, c2: 11, img1: lvl7_2, img2: lvl7_2 },
        { c1: 7, c2: 3, img1: lvl7_3, img2: lvl7_3 },
        { c1: 6, c2: 4, img1: lvl7_4, img2: lvl7_4 },
        { c1: 9, c2: 5, img1: lvl7_5, img2: lvl7_5 },
        { c1: 8, c2: 10, img1: lvl7_6, img2: lvl7_6 },
    ];

    const pairs9: IPair[] = [
        { c1: 5, c2: 2, img1: mlvl1_a1, img2: mlvl1_a2 },
        { c1: 9, c2: 8, img1: mlvl1_b1, img2: mlvl1_b2 },
        { c1: 11, c2: 1, img1: mlvl1_c1, img2: mlvl1_c2 },
        { c1: 3, c2: 10, img1: mlvl1_d1, img2: mlvl1_d2 },
        { c1: 4, c2: 0, img1: mlvl1_e1, img2: mlvl1_e2 },
        { c1: 7, c2: 6, img1: mlvl1_f1, img2: mlvl1_f2 },
    ];

    const pairs10: IPair[] = [
        { c1: 0, c2: 10, img1: mlvl2_a1, img2: mlvl2_a2 },
        { c1: 3, c2: 8, img1: mlvl2_b1, img2: mlvl2_b2 },
        { c1: 4, c2: 1, img1: mlvl2_c1, img2: mlvl2_c2 },
        { c1: 7, c2: 2, img1: mlvl2_d1, img2: mlvl2_d2 },
        { c1: 6, c2: 9, img1: mlvl2_e1, img2: mlvl2_e2 },
        { c1: 5, c2: 11, img1: mlvl2_f1, img2: mlvl2_f2 },
    ];

    const pairs11: IPair[] = [
        { c1: 4, c2: 2, img1: mlvl3_a1, img2: mlvl3_a2 },
        { c1: 9, c2: 5, img1: mlvl3_b1, img2: mlvl3_b2 },
        { c1: 11, c2: 6, img1: mlvl3_c1, img2: mlvl3_c2 },
        { c1: 10, c2: 7, img1: mlvl3_d1, img2: mlvl3_d2 },
        { c1: 1, c2: 0, img1: mlvl3_e1, img2: mlvl3_e2 },
        { c1: 3, c2: 8, img1: mlvl3_f1, img2: mlvl3_f2 },
    ];

    const pairs12: IPair[] = [
        { c1: 1, c2: 11, img1: mlvl4_a1, img2: mlvl4_a2 },
        { c1: 9, c2: 0, img1: mlvl4_b1, img2: mlvl4_b2 },
        { c1: 2, c2: 10, img1: mlvl4_c1, img2: mlvl4_c2 },
        { c1: 7, c2: 6, img1: mlvl4_d1, img2: mlvl4_d2 },
        { c1: 8, c2: 5, img1: mlvl4_e1, img2: mlvl4_e2 },
        { c1: 3, c2: 4, img1: mlvl4_f1, img2: mlvl4_f2 },
    ];

    const games: IMemoryCardsDict = {
        1: {
            ...pair2config(pairs1),
            instructions: lvl1Ins,
        },
        2: {
            ...pair2config(pairs2),
            instructions: lvl2Ins,
        },
        3: {
            ...pair2config(pairs3),
        },
        4: {
            ...pair2config(pairs4),
        },
        5: {
            ...pair2config(pairs5),
        },
        6: {
            ...pair2config(pairs6),
        },
        7: {
            ...pair2config(pairs7),
        },
        8: {
            ...pair2config(pairs8),
            instructions: lvl8Ins,
        },
        9: {
            ...pair2config(pairs9),
            instructions: math_sound_amount,
        },
        10: {
            ...pair2config(pairs10),
            instructions: math_sound_plus,
        },
        11: {
            ...pair2config(pairs11),
            instructions: math_sound_minus,
        },
        12: {
            ...pair2config(pairs12),
            instructions: math_sound_plus,
        },
    };

    return { games };
}
