import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import logoImage from '../assets/logo/blue.svg';
import MenuImg from '../assets/icons/blueMenu.svg';
import messages from '../assets/messages_he.json';
import MainLayout from '../common/layouts/MainLayout';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import cloudy from 'src/assets/folder/cloudy.png';
import rainbow from 'src/assets/folder/rainbow.png';
import light from 'src/assets/folder/light.png';
import FolderSwiper from './FolderSwiper';
import FolderWrap from './FolderWrap';
import Snackbar from '@mui/material/Snackbar';
import { useFolder } from './useFolder';
import './Folder.css';
import 'src/common/layouts/main_list/MainList.css';

const folders = [
    { id: '0', title: 'שיעור 1' },
    { id: '1', title: 'שיעור 2' },
    { id: '2', title: 'שיעור 3' },
    { id: '3', title: 'שיעור 4' },
    { id: '4', title: 'שיעור 5' },
    { id: '5', title: 'שיעור 6' },
    { id: '6', title: 'שיעור 7' },
    { id: '7', title: 'שיעור 8' },
    { id: 'moretask', title: 'משימות נוספות' },
];

function Folder(): JSX.Element {
    const { state } = useContext(AppContext);
    const { getMQid } = useMediaQueryWrap();
    const { selectedSubFolder, setSelectedSubFolder, emptyFolderAlert, setEmptyFolderAlert, onSubFolderClick } =
        useFolder();

    useFolder();
    const rid = getMQid();

    return (
        <MainLayout can_url="/folder" id={`folder_${rid}`} logo={logoImage} appMenuLogo={MenuImg} in_a_course>
            <div className="mlContent" style={{ overflowX: 'hidden' }}>
                <div className="mlTextWrapper">
                    <h1 className={'mlMainHeader'}>{messages['folder.header']}</h1>
                    <h2 className="mlSubHeader">
                        {messages['folder.subHeader1']} <br /> {messages['folder.subHeader2']}
                    </h2>
                </div>
                {selectedSubFolder.length > 0 && (
                    <FolderSwiper
                        iimages={selectedSubFolder}
                        on_close={() => setSelectedSubFolder([])}
                        on_delete={() => setSelectedSubFolder([])}
                    />
                )}
                <div className="my-folders-wrapper">
                    <div className="my-folders relative">
                        <img className="cloudy" src={cloudy} alt="cloudy" />
                        <img className="rainbow" src={rainbow} alt="rainbow" />
                        <img className="light" src={light} alt="light" />
                        <div className="my-folder-corner"></div>
                        <div className="folders-wrap flex justify-center z-1">
                            {folders.map((folder) => (
                                <FolderWrap
                                    is_empty={
                                        state.courses[state.course_key]?.img_folder.filter(
                                            (image) => image.sub_folder === folder.id,
                                        ).length === 0
                                    }
                                    key={folder.id}
                                    title={folder.title}
                                    sub_folder={folder.id}
                                    on_click={onSubFolderClick}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <Snackbar
                    open={emptyFolderAlert}
                    autoHideDuration={2500}
                    onClose={() => setEmptyFolderAlert(false)}
                    message={'התיקיה ריקה'}
                />
            </div>
        </MainLayout>
    );
}

export default Folder;
