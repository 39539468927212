import { useMediaQuery } from 'react-responsive';

export type mqId = 'mobile' | 'tablet' | 'desktop';

export function useMediaQueryWrap() {
    const isMobile = useMediaQuery({
        query: '(max-width: 768px)',
    });

    const isTablet = useMediaQuery({
        query: '(max-width: 1200px)',
    });

    const render_float_anim = useMediaQuery({
        query: '(min-height: 800px)',
    });

    const is_horiz = useMediaQuery({
        query: '(orientation: landscape)',
    });

    const getMQid = (): mqId => {
        if (isMobile) return 'mobile';
        if (isTablet) return 'tablet';
        return 'desktop';
    };

    const get_grid_list_cols = (): number => {
        const mqid = getMQid();
        if (mqid === 'mobile') return is_horiz ? 2 : 1;
        if (mqid === 'tablet') return 2;
        return 4;
    };

    const get_grid_list_width = (): string => {
        const mqid = getMQid();
        if (mqid === 'mobile') return '100%';
        if (mqid === 'tablet') return '75%';
        return '75%';
    };

    const get_modal_width = (): number => {
        const mqid = getMQid();
        if (mqid === 'mobile') return is_horiz ? 0.5 : 0.9;
        if (mqid === 'tablet') return is_horiz ? 0.5 : 0.9;
        return 1;
    };

    const should_turn_screen = (): boolean => {
        if (window.innerWidth === window.innerHeight) return false;
        return !is_horiz && getMQid() !== 'desktop';
    };

    const get_action_btn_zoom = (): number => {
        const mqid = getMQid();
        let action_btn_zoom = 1.25;
        if (mqid == 'mobile') action_btn_zoom = 0.6;
        if (mqid == 'tablet') action_btn_zoom = 0.8;
        return action_btn_zoom;
    };

    const isSmallHeader = useMediaQuery({
        query: '(max-width: 418px)',
    });

    const hide_header_middle = useMediaQuery({
        query: '(max-width: 1100px)',
    });

    return {
        is_horiz,
        isSmallHeader,
        hide_header_middle,
        getMQid,
        render_float_anim,
        get_grid_list_cols,
        get_grid_list_width,
        get_modal_width,
        should_turn_screen,
        get_action_btn_zoom,
    };
}
