import React, { useContext } from 'react';
import messages from '../assets/messages_he.json';
import penSvg from '../assets/drawings/pen.svg';
import bookSvg from '../assets/drawings/book.svg';
import paintSvg from '../assets/drawings/paint.svg';
import eraserSvg from '../assets/drawings/Eraser.svg';
import rudyImg from 'src/assets/images/card-rudy.svg';
import billyImg from 'src/assets/images/card-billy.svg';
import soonCardSvg from 'src/assets/images/card_soon.webp';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import { AppContext } from '../AppContext';
import { log_event } from '../common/common';
import { useNav } from 'src/common/useNav';
import { usePayment } from 'src/common/payment/usePayment';
import { useLogin } from 'src/common/login/useLogin';
import { CourseName } from 'src/wrapper';
import { useLocation } from 'react-router-dom';
import HomeCard from './HomeCard';
import TextButton from 'src/common/buttons/TextButton';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import Video from '../features/video/Video';
import playSvg from 'src/assets/icons/play.svg';
import googleSvg from 'src/assets/icons/google.svg';

const home_vid = 'https://youtu.be/RNd_tzPVsEE?si=ZUD0rHlCHuMdKrIv';

export function useHome() {
    const { state, setState } = useContext(AppContext);
    const { getMQid, render_float_anim } = useMediaQueryWrap();
    const { navigateToClassroom } = useNav();
    const { toggle_payment_modal } = usePayment();
    const { toggle_login_modal } = useLogin();
    const location = useLocation();
    const rid = getMQid();
    const [openVideoPop, setOpenVideoPop] = React.useState(false);
    const [play, setPlay] = React.useState(true);

    const render_drawings = () => {
        if (rid === 'mobile' || !render_float_anim) return null;
        return (
            <>
                <img src={penSvg} className="homePen float float-delay" alt="pen animation" />
                <img src={bookSvg} className="homeBook float float-delay" alt="book animation" />
                <img src={paintSvg} className="homePaint float" alt="paint animation" />
                <img src={eraserSvg} className="homeEraser float float-delay" alt="eraser animation" />
            </>
        );
    };

    const on_course_click = (course: CourseName) => {
        if (!state.subscribed) {
            log_event(`home_payment_modal_${course}`);
            toggle_payment_modal(course);
            return;
        }
        if (!(course in state.courses)) {
            log_event(`home_payment_modal_on_sale`);
            toggle_payment_modal('on_sale');
            return;
        }

        setState({ ...state, course_key: course, curr_game_group_idx: -1, curr_game_idx: -1 });
        const exclude_from_navigating_to_classroom = ['/classroom', '/more-tasks', '/games', '/folder'];
        if (!exclude_from_navigating_to_classroom.includes(location.pathname)) navigateToClassroom();
    };

    const handleClose = () => {
        setOpenVideoPop(false);
    };

    const render_action_button = () => {
        const text = state.subscribed ? messages['home.watch_vid'] : messages['home.login'];
        const on_click = state.subscribed
            ? () => {
                  setOpenVideoPop(true);
                  log_event('home_video_click');
              }
            : () => toggle_login_modal(true);
        const icon = state.subscribed ? playSvg : googleSvg;
        return (
            <div style={{ direction: 'ltr' }}>
                <TextButton
                    // aria-haspopup="dialog"
                    // aria-hidden={false}
                    onClick={on_click}
                    text={text}
                    textColor={'white'}
                    fontWeight={800}
                    fontSize={'1.1rem'}
                    padding="0.75em"
                    height="fit-content"
                    minWidth="200px"
                    border="1px solid white"
                    borderRadius="90px"
                    icon={icon}
                />
            </div>
        );
    };

    const render_video = () => {
        return (
            <Dialog open={openVideoPop} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogContent style={{ position: 'relative', padding: 0 }}>
                    <IconButton
                        className="videoCloseBtn"
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            color: 'black',
                            zIndex: 1,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Video url={home_vid} vid_over_cb={handleClose} play={play} setPlay={setPlay} controls={true} />
                </DialogContent>
            </Dialog>
        );
    };

    const render_course_cards = () => {
        return (
            <section className="SectionCards">
                <HomeCard
                    image={rudyImg}
                    title={messages['course.heb1_card']}
                    onClick={() => on_course_click('heb1')}
                    sub_text={messages['course.age_group1']}
                />
                <HomeCard
                    image={billyImg}
                    title={messages['course.math1_card']}
                    onClick={() => on_course_click('math1')}
                    sub_text={messages['course.age_group1']}
                />
                <HomeCard
                    image={soonCardSvg}
                    title={messages['course.heb2_card']}
                    soon
                    onClick={() => null}
                    sub_text={messages['course.age_group2']}
                />
            </section>
        );
    };

    return {
        render_course_cards,
        render_drawings,
        on_course_click,
        render_action_button,
        render_video,
    };
}
