import React from 'react';
import { buttonColors, buttonColorType } from './colors';
import './TextButton.css';

interface ILogoButton {
    onClick: () => void;
    text: string;
    textColor: string;
    fontSize?: string;
    fontWeight?: number;
    color?: buttonColorType;
    underline?: boolean;
    icon?: string;
    padding?: string;
    shadow?: string;
    lineHieight?: string;
    alignSelf?: string;
    width?: string;
    height?: string;
    disabled?: boolean;
    minWidth?: string;
    border?: string;
    borderRadius?: string;
}

function TextButton(props: ILogoButton): JSX.Element {
    const background = props.color ? buttonColors[props.color].background : 'none';
    let shadow = props.color ? `1px 2px 0px 3px ${buttonColors[props.color].shadow}` : 'none';
    if (props.shadow) shadow = props.shadow;
    return (
        <button
            className="textBtn"
            aria-label={props.text}
            onClick={props.onClick}
            style={{
                background: background,
                boxShadow: shadow,
                color: props.textColor,
                fontSize: props.fontSize,
                textDecoration: props.underline ? 'underline' : 'none',
                padding: props.padding,
                lineHeight: props.lineHieight,
                fontWeight: props.fontWeight,
                alignSelf: props.alignSelf,
                width: props.width,
                height: props.height,
                minWidth: props.minWidth,
                border: props.border,
                borderRadius: props.borderRadius,
            }}
            disabled={props.disabled}
        >
            {props.text}
            {props.icon && (
                <img src={props.icon} style={{ width: props.fontSize, height: props.fontSize }} alt={props.text} />
            )}
        </button>
    );
}

export default TextButton;
