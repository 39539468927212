import React, { useContext } from 'react';
import AppMenu from '../AppMenu';
import { useNav } from '../useNav';
import HeaderAction from '../header/HeaderAction';
import { useMediaQueryWrap } from '../useMediaQueryWrap';
import { layoutId } from './MainLayout';
import { course_key_to_icon, course_key_to_name } from 'src/wrapper';
import { AppContext } from 'src/AppContext';
import justLogoIcon from 'src/assets/logo/just-icon.svg';
import Divider from '@mui/material/Divider';
import messages from 'src/assets/messages_he.json';
import CircleButton from '../buttons/CircleButton';
import tasksSvg from 'src/assets/icons/more_tasks.svg';
import packSvg from 'src/assets/icons/pack.svg';
import myClassSVg from 'src/assets/icons/my_class.svg';
import gamesSvg from 'src/assets/icons/games.svg';
import homeSvg from 'src/assets/icons/home.svg';

import './MainHeader.css';

export interface IMainHeader {
    logo: string;
    appMenuLogo: string;
    id: layoutId;
    in_a_course?: boolean;
}

function MainHeader({ logo, appMenuLogo, id, in_a_course }: IMainHeader): JSX.Element {
    const { state } = useContext(AppContext);
    const { navigateToHome } = useNav();
    const { navigateToFolder, navigateToMoreTasks, navigateToClassroom, navigateToGames } = useNav();

    const { hide_header_middle } = useMediaQueryWrap();

    const render_course_name = () => {
        if (state.course_key === '') return;
        const course = state.course_key;
        const course_name = course_key_to_name[course];
        const img_svg = course_key_to_icon[course];
        return (
            <>
                <img className="" src={img_svg} alt="לוגו" height={50} width={50}></img>
                <p className="font-bold">{course_name}</p>
            </>
        );
    };

    const render_course_buttons = () => {
        if (state.course_key === '' || hide_header_middle) return;
        return (
            <div className="buttonsPannel">
                <div className="buttonWrap">
                    <CircleButton
                        zoom={0.5}
                        svg={myClassSVg}
                        color={'green'}
                        onClick={navigateToClassroom}
                        ariaLabel={messages['btn.classroom']}
                    />
                    <p className="">{messages['btn.classroom']}</p>
                </div>
                <div className="buttonWrap">
                    <CircleButton
                        zoom={0.5}
                        svg={packSvg}
                        color={'orange'}
                        onClick={navigateToFolder}
                        ariaLabel={messages['btn.pack']}
                    />
                    <p className="">{messages['btn.pack']}</p>
                </div>
                <div className="buttonWrap">
                    <CircleButton
                        zoom={0.5}
                        svg={tasksSvg}
                        color={'pink'}
                        onClick={navigateToMoreTasks}
                        ariaLabel={messages['btn.tasks']}
                    />
                    <p className="">{messages['btn.tasks']}</p>
                </div>
                <div className="buttonWrap">
                    <CircleButton
                        zoom={0.5}
                        svg={gamesSvg}
                        color={'purple'}
                        onClick={navigateToGames}
                        ariaLabel={messages['btn.games']}
                    />
                    <p className="">{messages['btn.games']}</p>
                </div>
                <div className="buttonWrap">
                    <CircleButton
                        zoom={0.5}
                        svg={homeSvg}
                        color={'yellow'}
                        onClick={navigateToHome}
                        ariaLabel={messages['btn.home']}
                    />
                    <p className="">{messages['btn.home']}</p>
                </div>
            </div>
        );
    };

    return (
        <header id={in_a_course ? 'in_course' : 'main'} className="mainLayoutHeader">
            <div className="mainLayoutHeaderSide right">
                <img
                    id={in_a_course ? 'in_course' : 'main'}
                    className="logoBtn in_course"
                    src={in_a_course ? justLogoIcon : logo}
                    onClick={() => navigateToHome()}
                    alt={'לדף הבית'}
                />
                {in_a_course && (
                    <>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        {render_course_name()}
                    </>
                )}
            </div>
            {in_a_course && render_course_buttons()}
            <div className="mainLayoutHeaderSide">
                <HeaderAction id={id} />
                <AppMenu logo={appMenuLogo} />
            </div>
        </header>
    );
}

export default MainHeader;
