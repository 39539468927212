import React from 'react';
import { useMediaQueryWrap } from '../../common/useMediaQueryWrap';
import Turnscreen from '../../common/layouts/TurnScreen';
import useSound from 'use-sound';
import sccSound from 'src/assets/sounds/success1.mp3';
import { usePaintMazeConfig } from './PaintMazeConfig';
import PaintMazeCell from './PaintMazeCell';
import PaintMazeAnswer from './PaintMazeAnswer';
import PaintMazeMouse from './PaintMazeMouse';
import megaphoneSvg from 'src/assets/icons/megaphone.svg';
import CircleButton from 'src/common/buttons/CircleButton';
import './PaintMaze.css';

export interface IPaintMaze {
    width: number;
    height: number;
    onFinish: () => void;
    lvl: number;
}

const columns = 15;
const rows = 7;
const cells = columns * rows;

function PaintMaze({ width, height, onFinish, lvl }: IPaintMaze): JSX.Element {
    const { should_turn_screen, getMQid } = useMediaQueryWrap();
    const [playSuccess] = useSound(sccSound, { volume: 0.2 });
    const { games } = usePaintMazeConfig();
    const [currAnswer, setCurrAnswer] = React.useState(games[lvl].default_answer);
    const [started, setStarted] = React.useState(false);
    const [playInstructions] = useSound(games[lvl].instructions, {
        volume: 1,
    });

    const cell_init_state = [...Array(cells).keys()].map((_, index) => {
        const config_cell = games[lvl].cells[index];
        if (config_cell.color && !config_cell.riddle) return true;
        return false;
    });
    const [cellStates, setCellStates] = React.useState(cell_init_state);

    const correct_clicks_needed = games[lvl].cells.filter((cell) => cell.color && cell.riddle).length;
    const [correctClicks, setCorrectClicks] = React.useState(0);

    const col_step = width / columns;
    const row_step = height / rows;

    const render_size = Math.min(col_step, row_step);
    const min_step = Math.min(col_step, row_step);

    const flat_idx_to_pos = (idx: number) => {
        const x_idx = idx % columns;
        const y_idx = Math.floor(idx / columns);

        let left = width - min_step * (x_idx + 1);
        let top = height - min_step * (rows - y_idx);

        const spare_size = Math.max(col_step, row_step) - min_step;

        if (row_step > col_step) top -= (spare_size * (columns - 1)) / 4;
        else left -= (spare_size * (columns - 1)) / 2;

        return {
            left,
            top,
        };
    };

    const cell_pos = [...Array(cells).keys()].map((_, index) => {
        return flat_idx_to_pos(index);
    });

    const on_cell_click = (index: number) => {
        if (!started) return;

        const answer = games[lvl].cells[index].color;
        if (!answer) return;

        if (answer === currAnswer) {
            playSuccess();
            const new_states = [...cellStates];
            new_states[index] = true;
            setCellStates(new_states);
            setCorrectClicks(correctClicks + 1);

            if (correctClicks + 1 === correct_clicks_needed) {
                onFinish();
            }
        }
    };

    // canvas related logic for the mouse painter
    const paintCanvasRef: React.RefObject<HTMLDivElement> = React.createRef();

    const [painterPosition, setPainterPosition] = React.useState({ x: 0, y: 0 });
    const [isPainterClicked, setPainterClicked] = React.useState(false);

    const handleMouseMove = (event: any) => {
        if (!paintCanvasRef.current) return;
        const canvasRect = paintCanvasRef.current.getBoundingClientRect();
        const mouseX = event.clientX - canvasRect.left;
        const mouseY = event.clientY - canvasRect.top;

        if (mouseX >= 0 && mouseX <= canvasRect.width && mouseY >= 0 && mouseY <= canvasRect.height) {
            setPainterPosition({ x: mouseX, y: mouseY });
        }
    };

    const handleCanvasClick = () => {
        setPainterClicked(true);
        setTimeout(() => {
            setPainterClicked(false);
        }, 300);
    };

    const onInsturctionClick = () => {
        playInstructions();
        setStarted(true);
    };

    const render = () => {
        if (should_turn_screen()) return <Turnscreen />;
        return (
            <div
                className="paint_maze_wrap"
                style={{ width: width, height: height }}
                ref={paintCanvasRef}
                onMouseMove={handleMouseMove}
                onClick={handleCanvasClick}
            >
                {cell_pos.map((cell, index) => (
                    <PaintMazeCell
                        index={index}
                        key={index}
                        size={render_size}
                        top={cell.top}
                        left={cell.left}
                        text={games[lvl].cells[index].riddle ?? ''}
                        color={games[lvl].cells[index].color}
                        isCorrect={cellStates[index]}
                        on_click={games[lvl].cells[index].color ? on_cell_click : undefined}
                    />
                ))}
                {games[lvl].answer_control.map((index) => (
                    <PaintMazeAnswer
                        key={index}
                        size={render_size}
                        top={cell_pos[index].top}
                        left={cell_pos[index].left}
                        text={games[lvl].cells[index].fixed_answer!.text}
                        color={games[lvl].cells[index].fixed_answer!.color}
                        on_click={() => setCurrAnswer(games[lvl].cells[index].fixed_answer!.color)}
                        is_selected={currAnswer === games[lvl].cells[index].fixed_answer!.color}
                    />
                ))}

                {getMQid() === 'desktop' && (
                    <PaintMazeMouse
                        x={painterPosition.x}
                        y={painterPosition.y}
                        color={currAnswer}
                        clicked={isPainterClicked}
                    />
                )}

                {!started && (
                    <div
                        className="game_instruction"
                        style={{
                            width: render_size * 2,
                            height: render_size * 2,
                            top: cell_pos[1].top,
                            left: cell_pos[1].left,
                        }}
                    >
                        <CircleButton
                            svg={megaphoneSvg}
                            color={'white'}
                            onClick={onInsturctionClick}
                            zoom={(render_size / 85.3) * 2}
                            ariaLabel="הוראות"
                            inf_anim={'normal'}
                        />
                    </div>
                )}
            </div>
        );
    };

    return render();
}

export default PaintMaze;
