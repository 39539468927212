import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import Video from '../features/video/Video';
import { games } from 'src/metadata';
import { useClassProgMicTask } from 'src/class/useClassProgMicTask';
import { useClassPuzzle } from 'src/class/useClassPuzzle';
import { useClassSizes } from 'src/class/useClassSizes';
import { useClassMaze } from 'src/class/useClassMaze';
import Mic from 'src/features/mic/Mic';
import { useMediaQueryWrap } from 'src/common/useMediaQueryWrap';
import Answers from 'src/features/answers/Answers';
import megaphoneSvg from 'src/assets/icons/megaphone.svg';
import CircleButton from 'src/common/buttons/CircleButton';
import useSound from 'use-sound';
import game_book1_sound from 'src/assets/sounds/game_book_1.mp3';
import { useClassWordDrag } from 'src/class/useClassWordDrag';
import { useClassMemoryCards } from 'src/class/useClassMemoryCards';
import { useClassPaintMaze } from 'src/class/useClassPaintMaze';
import { useNav } from 'src/common/useNav';
import { useClassCashGame } from 'src/class/useClassCashGame';

interface instructionsDict {
    [key: string]: any;
}

const instructions: instructionsDict = {
    game_book_1: game_book1_sound,
};

export function useGame() {
    const { state } = useContext(AppContext);
    const [play, setPlay] = React.useState(true);
    const [gameOver, setGameOver] = React.useState(false);
    const [vidOver, setVidOver] = React.useState(false);
    const [mulTaskIdx, setMulTaskIdx] = React.useState(0);
    const { navigateToGames } = useNav();

    const { get_action_btn_zoom } = useMediaQueryWrap();

    React.useEffect(() => {
        if (
            state.curr_game_group_idx === -1 ||
            state.curr_game_idx === -1 ||
            state.course_key === '' ||
            !(state.course_key in state.courses)
        )
            navigateToGames();
    }, []);

    const get_game = () => {
        if (state.course_key === '') return;
        if (!(state.course_key in state.courses)) return;

        if (state.curr_game_group_idx === -1 || state.curr_game_idx === -1) return;
        const game = games[state.course_key][state.curr_game_group_idx].games[state.curr_game_idx];
        return game;
    };

    const get_task = () => {
        const game = get_game();
        if (!game) return;
        return game.task ?? game.mul_tasks?.[mulTaskIdx];
    };

    const { get_vid_width, letterZoom, contentHeight } = useClassSizes({ task: get_task() });
    const [playProgVid, setPlayProgVid] = React.useState(true);

    const game_over = () => {
        setVidOver(true);
        const task = get_task();
        if (task?.micTask) return;
        if (task?.answers) return;
        setGameOver(true);
    };

    const { render_mic_prog, render_ab_ruler_prog, nextProgTask, progTaskStopIndex } = useClassProgMicTask({
        task: get_task(),
        letterZoom,
        setPlayProgVid,
        on_task_over: game_over,
    });

    const { render_puzzle, is_puzzle } = useClassPuzzle({ task: get_task(), contentHeight, onFinish: game_over });
    const { render_maze, is_maze } = useClassMaze({ task: get_task(), contentHeight, onFinish: game_over });
    const { render_paint_maze, is_paint_maze } = useClassPaintMaze({
        task: get_task(),
        contentHeight,
        onFinish: game_over,
    });

    const { render_memory_cards, is_memory_cards } = useClassMemoryCards({
        task: get_task(),
        contentHeight,
        onFinish: game_over,
    });

    const on_finish_mul_task_game = () => {
        const game = get_game();
        if (!game?.mul_tasks) return;
        if (mulTaskIdx === game.mul_tasks.length - 1) setGameOver(true);
        else setMulTaskIdx(mulTaskIdx + 1);
    };

    const { render_word_drag_game, is_word_drag_game } = useClassWordDrag({
        task: get_task(),
        contentHeight,
        onFinish: on_finish_mul_task_game,
    });

    const { render_cash_game, is_cash_game } = useClassCashGame({
        task: get_task(),
        contentHeight,
        onFinish: on_finish_mul_task_game,
    });

    const render_video = () => {
        const task = get_task();
        if (!task) return null;
        if (task.video_url === '') return null;
        const vid_frame = task.progressVideo ? (
            <Video
                play={playProgVid}
                setPlay={setPlayProgVid}
                url={task.video_url}
                controls={false}
                progressInterval={250}
                vid_over_cb={game_over}
                mulTasks={{
                    stops: task.progressVideo.video_stops,
                    task_over_cb: () => nextProgTask(true),
                    curr_stop: progTaskStopIndex,
                }}
            />
        ) : (
            <Video play={play} controls={true} setPlay={setPlay} url={task.video_url} vid_over_cb={game_over} />
        );
        return <div style={{ width: get_vid_width }}>{vid_frame}</div>;
    };

    const next_video = () => {
        const game = get_game();
        if (!game?.mul_tasks) return;
        setVidOver(false);
        if (mulTaskIdx === game.mul_tasks.length - 1) setGameOver(true);
        else {
            setMulTaskIdx(mulTaskIdx + 1);
            setPlay(true);
        }
    };

    const render_mic = () => {
        const task = get_task();

        if (!vidOver || !task) return null;
        if (!task.micTask) return null;
        return (
            <Mic
                task={task.micTask}
                btn_zoom={get_action_btn_zoom()}
                mic_over_cb={next_video}
                successSound={task.successSound}
            />
        );
    };

    const render_answers_during = () => {
        const task = get_task();

        if (!task?.answers) return null;
        return (
            <Answers
                answers={task.answers.answers}
                correct={task.answers.correct}
                onCorrect={next_video}
                zoom={letterZoom}
                successSound={task.successSound}
            />
        );
    };

    /* instructions */
    const [durInstructions, setDurInstructions] = React.useState(false);
    const get_game_sound = () => {
        const task = get_task();
        return task?.instructions ? instructions[task.instructions] : undefined;
    };

    const [playInstructions] = useSound(get_game_sound(), {
        volume: 1,
        onend: () => {
            setDurInstructions(false);
        },
    });
    const onInsturctionClick = () => {
        if (durInstructions) return;
        playInstructions();
        setDurInstructions(true);
    };

    const render_game_instructions = () => {
        const task = get_task();
        if (!task?.instructions) return null;
        return (
            <CircleButton
                svg={megaphoneSvg}
                color={'white'}
                onClick={onInsturctionClick}
                zoom={1.2}
                ariaLabel="הוראות"
                inf_anim={'normal'}
            />
        );
    };

    return {
        gameOver,
        is_puzzle,
        render_puzzle,
        render_maze,
        is_maze,
        render_word_drag_game,
        is_word_drag_game,
        render_memory_cards,
        is_memory_cards,
        render_paint_maze,
        is_paint_maze,
        render_video,
        render_mic_prog,
        render_ab_ruler_prog,
        render_mic,
        render_answers_during,
        render_game_instructions,
        render_cash_game,
        is_cash_game,
    };
}
