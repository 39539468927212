import React from 'react';
import shipSvg from '../../assets/images/ship_place.webp';
import './Puzzle.css';

export interface IPuzzlePlace {
    size: number;
    x: number;
    y: number;
}

function PuzzlePlace(props: IPuzzlePlace): JSX.Element {
    return (
        <div className="puzzlePlaceWrap" style={{ top: props.y, left: props.x, width: props.size, height: props.size }}>
            <img src={shipSvg} width={props.size} height={props.size} alt="תמונת פאזל"></img>
        </div>
    );
}

export default PuzzlePlace;
