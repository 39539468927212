import React from 'react';
import { useMediaQueryWrap } from 'src/common/useMediaQueryWrap';
import { ITask } from 'src/wrapper';

export interface IClassSizes {
    task?: ITask;
    is_more_task?: boolean;
}
export function useClassSizes({ task, is_more_task }: IClassSizes) {
    const [vidWidthHoriz, setVidWidthHoriz] = React.useState(window.innerWidth * 0.5);
    const [contentHeight, setContentHeight] = React.useState(window.innerHeight * 0);
    const [letterZoom, setLetterZoom] = React.useState(1);
    const { is_horiz, getMQid } = useMediaQueryWrap();

    const get_elements_sizes = () => {
        const main = document.getElementsByClassName('mainLayoutContent');
        const header = document.getElementsByClassName('mainLayoutHeader');
        const footer = document.getElementsByClassName('appRightsWrapper');
        if (!main || !header || !footer) return { content_height: 0, vid_horiz_width: 0, letter_zoom: 0 };

        const rid = getMQid();
        const main_e = main[0];
        const header_e = header[0];
        const footer_e = footer[0];
        const class_progress = 50;

        const content_height = main_e.clientHeight - header_e.clientHeight - footer_e.clientHeight - class_progress;
        const vid_horiz_width = Math.min(content_height / 0.5625, main_e.clientWidth);

        // set letter button zoom based on width screen
        let letter_zoom = 1;
        if (is_horiz) {
            const letter_width = main_e.clientWidth / 22;
            letter_zoom = Math.min(letter_width / 64, 1);
        } else {
            if (rid == 'mobile') letter_zoom = 0.6;
            if (rid == 'tablet') letter_zoom = 0.8;
        }

        return { content_height, vid_horiz_width, letter_zoom };
    };

    React.useEffect(() => {
        const { content_height, vid_horiz_width, letter_zoom } = get_elements_sizes();
        if (!contentHeight && !vidWidthHoriz && !letterZoom) return;
        setVidWidthHoriz(vid_horiz_width);
        setLetterZoom(letter_zoom);
        setContentHeight(content_height);
    }, [is_horiz]);

    const is_space_taken_by_task = (task?: ITask): boolean => {
        if (!task) return false;
        if (
            task.ab_highlight ||
            task.ab_select ||
            task.micTask?.ab_match ||
            task.progressVideo?.ab_ruler ||
            task.ab_select_hold ||
            task.ab_ruler_match ||
            task.answers
        ) {
            return true;
        } else return false;
    };

    const is_space_taken = is_space_taken_by_task(task);

    const get_vid_width_aux = (divWidthHoriz: number): number | string => {
        const mqid = getMQid();
        let vid_width: string | number = is_horiz ? divWidthHoriz * 0.85 : '75%';
        if (mqid === 'tablet') vid_width = is_horiz ? divWidthHoriz * 0.95 : '80%';
        if (mqid === 'mobile') vid_width = is_horiz ? divWidthHoriz * 1 : '90%';
        return vid_width;
    };

    const get_vid_width = React.useMemo(() => {
        if ((is_horiz && is_space_taken) || is_more_task) {
            const vid_width = vidWidthHoriz * 0.9 * 0.9;
            return vid_width;
        } else return get_vid_width_aux(vidWidthHoriz);
    }, [is_space_taken, vidWidthHoriz]);

    return {
        vidWidthHoriz,
        contentHeight,
        letterZoom,
        get_vid_width,
    };
}
