import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import logoImage from '../assets/logo/blue.svg';
import messages from '../assets/messages_he.json';
import MenuImg from '../assets/icons/blueMenu.svg';
import MainLayout from '../common/layouts/MainLayout';
import MainList from '../common/layouts/main_list/MainList';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import { useMoreTasks } from './useMoreTasks';
import MainListItem from '../common/layouts/main_list/MainListItem';
import { more_tasks_metadata } from 'src/metadata';
import { useNav } from '../common/useNav';
import { IMoreTask } from 'src/wrapper';
import '../common/layouts/main_list/MainList.css';
import './moreTasks.css';

function MoreTasks(): JSX.Element {
    const { state } = useContext(AppContext);
    const { getMQid } = useMediaQueryWrap();
    const { render_drawings, selectMoreTask, is_locked } = useMoreTasks();
    const { navigateToFolder } = useNav();

    const rid = getMQid();

    const get_moretasks_list = (): IMoreTask[] => {
        if (state.course_key === '') return [];
        if (!(state.course_key in state.courses)) {
            const amount = more_tasks_metadata[state.course_key].length;
            const locked_mt: IMoreTask[] = Array(amount).fill({
                locked: true,
                progress: 0,
            });
            return locked_mt;
        } else return state.courses[state.course_key].more_tasks;
    };

    return (
        <MainLayout can_url="/more-tasks" id={`moretasks_${rid}`} logo={logoImage} appMenuLogo={MenuImg} in_a_course>
            <MainList
                text={
                    <>
                        <h1 className={'mlMainHeader'}>{messages['moretasks.header']}</h1>
                        <h2 className="mlSubHeader">
                            {messages['moretasks.subHeader1']}
                            <button
                                id={'link'}
                                className="mlSubHeader"
                                onClick={navigateToFolder}
                                aria-label={messages['moretasks.link1']}
                            >
                                {messages['moretasks.link1']}
                            </button>
                            {'.'}
                            <br></br>
                            זו הזדמנות נהדרת לכתוב מילים שאפשר לשלוח למי שאוהבים
                        </h2>
                    </>
                }
                mainList={get_moretasks_list().map((item, index) => {
                    const mt_meta =
                        state.course_key != ''
                            ? more_tasks_metadata[state.course_key][index]
                            : more_tasks_metadata['heb1'][index];
                    return (
                        <MainListItem
                            main_img={mt_meta.img}
                            key={index}
                            title={mt_meta.title}
                            materials={mt_meta.materials}
                            locked={is_locked(index)}
                            progress={item.progress}
                            onClick={() => selectMoreTask(index)}
                        />
                    );
                })}
            />
            {render_drawings()}
        </MainLayout>
    );
}

export default MoreTasks;
