import React from 'react';
import { useMediaQueryWrap } from '../useMediaQueryWrap';
import Popover from '@mui/material/Popover';
import { useUserProfile } from './useUserProfile';
import messages from '../../assets/messages_he.json';
import Divider from '@mui/material/Divider';
import './HeaderAction.css';

export interface IUserProfile {
    anchorEl: null | HTMLElement;
    openUserMenu: boolean;
    closeUserMenu: () => void;
}

function UserProfile({ anchorEl, openUserMenu, closeUserMenu }: IUserProfile): JSX.Element {
    const { is_horiz } = useMediaQueryWrap();

    const { render_logout_item, render_more_courses, render_user_courses, render_course } = useUserProfile({
        closeUserMenu,
    });

    return (
        <>
            <Popover
                id={'user_profile_menu'}
                open={openUserMenu}
                anchorEl={anchorEl}
                onClose={closeUserMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: is_horiz ? 'right' : 'center',
                }}
            >
                <div className="UserMenuWrap">
                    <p className="UserMenuSubHeader">{messages['user_profile.my_class']}</p>
                    {render_user_courses()}
                    <Divider variant="middle" style={{ color: 'gray', marginTop: '0.75em' }}></Divider>
                    <p className="UserMenuSubHeader">{messages['user_profile.more_class']}</p>
                    {render_more_courses()}
                    {render_course('heb2')}
                    {render_logout_item()}
                </div>
            </Popover>
        </>
    );
}

export default UserProfile;
