import React from 'react';
import animationData from 'src/assets/anim/confetti.json';
import Lottie from 'lottie-react';
import trophySvg from 'src/assets/anim/trophy.svg';
import TextButton from 'src/common/buttons/TextButton';
import messages from 'src/assets/messages_he.json';
import Modal from '@mui/material/Modal';
import { useMediaQueryWrap } from 'src/common/useMediaQueryWrap';

export interface IClassFinishedAnim {
    finish_cb: Function;
    open: boolean;
}

function ClassFinishedAnim({ finish_cb, open }: IClassFinishedAnim): JSX.Element {
    const { is_horiz } = useMediaQueryWrap();
    const height = is_horiz ? '50vh' : '60vh';

    return (
        <Modal open={open} onClose={() => null}>
            <div
                onClick={() => finish_cb()}
                style={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    top: '30%',
                    left: '50%',
                    transform: 'translate(-50%, -30%)',
                    width: '90%',
                    maxWidth: '600px',
                    height: height,
                    zIndex: 5,
                    cursor: 'pointer',
                }}
            >
                <p style={{ fontWeight: 'bold', color: 'white', textAlign: 'center', fontSize: '2em' }}>
                    {messages['class.finished_anim_title']}
                </p>
                <img
                    src={trophySvg}
                    alt="trophy image"
                    style={{
                        position: 'relative',
                        zIndex: 2,
                        marginTop: '20px',
                        maxWidth: '80%',
                        height: 'auto',
                    }}
                />
                <div style={{ position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)', zIndex: 3 }}>
                    <Lottie
                        loop={true}
                        autoPlay
                        animationData={animationData}
                        style={{ width: '100%', height: 'auto' }}
                    />
                </div>
                <div style={{ position: 'relative', zIndex: 5, marginTop: '20px' }}>
                    <TextButton
                        onClick={() => finish_cb()}
                        text={messages['class.finished_anim_next']}
                        color={'green'}
                        textColor={'white'}
                        fontSize={'1.5em'}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default ClassFinishedAnim;
