import React from 'react';
import './StarProgress.css';

interface IStarProgress {
    text: string;
    zoom: number;
    text_color: string;
    bg_color: string;
}

function StarProgress(props: IStarProgress): JSX.Element {
    return (
        <div className="star_wrapper" style={{ zoom: props.zoom }} aria-label={props.text}>
            <p className="star_text" style={{ color: props.text_color }}>
                {props.text}
            </p>
            <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.2089 1.60489C14.9443 0.124778 17.0557 0.124778 17.7911 1.60489L21.1415 8.34805C21.4327 8.93418 21.9923 9.34073 22.6397 9.43657L30.0881 10.5392C31.7231 10.7812 32.3755 12.7893 31.1951 13.9461L25.8173 19.2162C25.3498 19.6743 25.1361 20.3321 25.245 20.9775L26.4981 28.4021C26.7731 30.0318 25.0649 31.2729 23.6 30.5077L16.926 27.0217C16.3458 26.7186 15.6542 26.7186 15.074 27.0217L8.40001 30.5077C6.93508 31.2729 5.22691 30.0318 5.50195 28.4021L6.75498 20.9775C6.86389 20.3321 6.65016 19.6743 6.18271 19.2162L0.804899 13.9461C-0.37552 12.7893 0.276941 10.7812 1.91186 10.5392L9.36031 9.43657C10.0077 9.34073 10.5673 8.93418 10.8585 8.34805L14.2089 1.60489Z"
                    fill={props.bg_color}
                />
            </svg>
        </div>
    );
}

export default StarProgress;
