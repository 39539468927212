import React from 'react';
import logoImage from '../assets/logo/white.svg';
import MenuImg from '../assets/icons/yellowMenu.svg';
import MainLayout from '../common/layouts/MainLayout';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import { useHome } from './useHome';
import './Home.css';
// import MicTest from '../features/mic/MicTest';

function Home(): JSX.Element {
    const { render_course_cards, render_drawings, render_action_button, render_video } = useHome();
    const { getMQid } = useMediaQueryWrap();
    const rid = getMQid();

    const render_home = () => {
        return (
            <MainLayout can_url="/home" id={`home_${rid}`} logo={logoImage} appMenuLogo={MenuImg}>
                <div id={rid} className="homeContent">
                    <div className="m-100 desk-pr-1 text-center">
                        <h1 className="title-42 text-white">ברוכים הבאים לדמיון בקופסא</h1>
                        <h3 className="text-xl font-bold text-white">
                            סיפורים אינטראקטיביים לרכישת מיומנויות חשבון, קריאה וכתיבה, לפני כיתה א׳ ובמהלכה
                        </h3>
                    </div>
                    {render_action_button()}
                    {render_course_cards()}
                    {render_video()}
                </div>
                {render_drawings()}
                {/* <MicTest /> */}
            </MainLayout>
        );
    };

    return render_home();
}

export default Home;
